import React, { useContext } from 'react';
import firebase from 'firebase/compat/app';
import { selectedEventsContext } from '../../context/SelectedEventList';

const SelectAll = () => {
  // eslint-disable-next-line
  const [events, setEvents] = useContext(selectedEventsContext);

  const handleCheckallChange = () => {
    //get all checkboxes
    const checkboxes = document.querySelectorAll('[id=proje]');

    //cycle through boxes to check if they are checked
    for (let i = 0; i < checkboxes.length; i++) {
      //check if boxes are not checked
      if (checkboxes[i].checked === false) {
        //set all boxes checker
        checkboxes[i].checked = true;

        //get firestore document
        var docRef = firebase
          .firestore()
          .collection('evenementen')
          .doc(checkboxes[i].value);

        //if document exists get data
        docRef.get().then((doc) => {
          if (doc.exists) {
            setEvents((events) => [...events, doc.data()]);
          }
        });
      } else {
        checkboxes[i].checked = false;
      }
    }
  };

  return (
    <p>
      <label>
        <input
          type="checkbox"
          className="filled-in CHCH"
          defaultChecked={false}
          onChange={handleCheckallChange}
        />
        <span></span>
      </label>
    </p>
  );
};

export default SelectAll;
