const initState = {
  authError: null
}

const authReducer = (state = initState, action) => {
  switch(action.type){
    case 'LOGIN_ERROR':
      console.log('login error')
      return {
        ...state,
        authError: 'Login failed'
      }
    case 'LOGIN_SUCCES':
      console.log('login succes')
      return{
        ...state,
        authError: null
      }
    case 'SIGNOUT_SUCCES':
      console.log('signout succes')
      return state;
    case 'SIGNUP_SUCCES':
      console.log('signup succes')
      return {
        ...state,
        authError: null
      }
    case 'SIGNUP_ERROR':
      console.log('signup error')
      return {
        ...state,
        authError: action.err.message
      }
      case 'UPDATE_EMAIL_SUCCES':
      console.log('updated email succes')
      return {
        ...state,
        authError: null
      }
    case 'UPDATE_EMAIL_ERROR':
      console.log('updated email error')
      return {
        ...state,
        authError: action.err.message
      }
      case 'UPDATE_PASSWORD_SUCCES':
      console.log('updated Password succes')
      return {
        ...state,
        authError: null
      }
    case 'UPDATE_PASSWORD_ERROR':
      console.log('updated Password error')
      return {
        ...state,
        authError: action.err.message
      }
    case 'UPDATE_USER_VIEW':
      console.log('updated user dashboardview succes')
      return {
        ...state,
        authError: null
      }
    case 'UPDATE_USER_VIEW_ERROR':
      console.log('updated user dashboardview error')
      return {
        ...state,
        authError: action.err.message
      }
    default:
      return state;
  }
}

export default authReducer
