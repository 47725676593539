import React, { Component, useContext, useEffect, useState } from 'react';
import SelectedHeaderList from '../../context/SelectedHeaderList';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import { Plus, X, Download, Eye, Trash, Copy } from 'react-feather';
import {
  deleteEvenement,
  copyEvenement,
} from '../../store/actions/evenementActions';
import { selectedEventsContext } from '../../context/SelectedEventList';
import { isMobile } from 'react-device-detect';
import BigCalendar from './BigCalendar';
import firebase from 'firebase/compat/app';

import ExportModal from '../models/ExportModal';
import EventAdd from '../models/EventAdd';

// import KVMLogo from '../../images/KVM-logo.svg';
import KVMLogoLight from '../../images/KVM-logo-light.svg';
import HeroImage from '../../images/Ronnie.jpeg';

import moment from 'moment';
import 'moment/locale/nl';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  InfiniteHits,
  SearchBox,
  SortBy,
  Configure,
  RefinementList,
  createInfiniteHitsSessionStorageCache,
} from 'react-instantsearch-dom';
import qs from 'qs';
import SelectAll from './SelectAll';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

moment.locale('nl');
const DEBOUNCE_TIME = 700;

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = (location) => qs.parse(location.search.slice(1));

const searchClient = algoliasearch(
  'K6F3MGUOY7',
  'c252b81ecaaff310e21d0b3102ae6606'
);

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

class Dashboard extends Component {
  /* eslint-disable */
  static contextType = selectedEventsContext;

  constructor(props) {
    super(props);
    this.state = {
      exportshow: false,
      addshow: false,
      calendar: false,
      startfilter: moment().subtract(1, 'd').valueOf(),
      eindfilter: moment().add(1, 'y').valueOf(),
      searchState: urlToSearchState(this.props.location),
      lastLocation: this.props.location,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.lastLocation) {
      return {
        searchState: urlToSearchState(props.location),
        lastLocation: props.location,
      };
    }

    return null;
  }

  onSearchStateChange = (searchState) => {
    clearTimeout(this.debouncedSetState);

    this.debouncedSetState = setTimeout(() => {
      this.props.history.push(
        searchStateToUrl(this.props, searchState),
        searchState
      );
    }, DEBOUNCE_TIME);

    this.setState({ searchState });
  };

  componentDidUpdate(e) {
    const exportBtn = document.getElementById('modalOpen');

    if (exportBtn) {
      if (e.length > 0) {
        exportBtn.classList.remove('disabledBtn');
      }
      if (e.length === 0) {
        exportBtn.classList.add('disabledBtn');
      }
    }
  }

  handleCheckallChange = () => {
    //get all checkboxes
    const checkboxes = document.querySelectorAll('[id=proje]');

    //cycle through boxes to check if they are checked
    for (let i = 0; i < checkboxes.length; i++) {
      //check if boxes are not checked
      if (checkboxes[i].checked === false) {
        //set all boxes checker
        checkboxes[i].checked = true;
      } else {
        checkboxes[i].checked = false;
      }
    }
  };

  handleViewChange = (e) => {
    this.setState(() => ({
      calendar: e.target.checked,
    }));
  };

  handleStartFilterChange = (e) => {
    this.setState(() => {
      return { startfilter: moment(e.target.value).valueOf() };
    });
  };

  handleEindFilterChange = (e) => {
    this.setState(() => {
      return { eindfilter: moment(e.target.value).valueOf() };
    });
  };

  render() {
    const { auth } = this.props; // eslint-disable-next-line
    const [events, setEvents] = this.context;
    this.componentDidUpdate(events);

    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div className="dashboard container-wide">
        <div className="row">
          <div className="col s12">
            <div id="toasty"></div>
            <div className="hero-container">
              <img className="hero-image" src={HeroImage} alt="Hero Image" />
              <div className="title hero-title">
                <h1>EVENEMENTEN</h1>
                <img className="KVM-small" src={KVMLogoLight} alt="KVM Logo" />
              </div>
            </div>
            <InstantSearch
              searchClient={searchClient}
              indexName="Events"
              searchState={this.state.searchState}
              onSearchStateChange={this.onSearchStateChange}
              createURL={createURL}
              refresh
            >
              <Configure
                filters={`einddatum:${this.state.startfilter} TO ${this.state.eindfilter}`}
              />
              <div className="searchBar">
                <div className="actions">
                  <div className="utility-bar">
                    <SelectedHeaderList>
                      <div className="first-row">
                        {this.state.calendar === false && (
                          <>
                            <SearchBox
                              reset={<X size={20} />}
                              translations={{
                                placeholder:
                                  'Zoekbalk voor projecten / partners / plaats / provincie / etc…',
                              }}
                            />
                            {isMobile === false && (
                              <>
                                <button
                                  className="btn-icon disabledBtn"
                                  id="modalOpen"
                                  onClick={() =>
                                    this.setState({
                                      exportshow: true,
                                    })
                                  }
                                >
                                  <span>
                                    <Download size={20} />
                                  </span>
                                  Export
                                </button>
                                <ExportModal
                                  show={this.state.exportshow}
                                  onClose={() =>
                                    this.setState({
                                      exportshow: false,
                                    })
                                  }
                                />
                              </>
                            )}
                          </>
                        )}
                        <div className="switch">
                          <label>
                            List
                            <input
                              type="checkbox"
                              onChange={this.handleViewChange}
                            />
                            <span className="lever"></span>
                            Calendar
                          </label>
                        </div>
                      </div>
                      {this.state.calendar === false && (
                        <div className="second-row">
                          <div className="date-filter">
                            <div className="date-container">
                              <input
                                type="date"
                                className="datum filter"
                                defaultValue={moment(
                                  this.state.startfilter
                                ).format('YYYY-MM-DD')}
                                onChange={this.handleStartFilterChange}
                              ></input>
                            </div>
                            <p>Tot</p>
                            <div className="date-container">
                              <input
                                type="date"
                                className="datum filter"
                                defaultValue={moment(
                                  this.state.eindfilter
                                ).format('YYYY-MM-DD')}
                                onChange={this.handleEindFilterChange}
                              ></input>
                            </div>
                          </div>
                          <SortBy
                            defaultRefinement="Events_replica"
                            items={[
                              { value: 'Events_replica', label: 'Sorteren op' },
                              {
                                value: 'Events_projectnaam_asc',
                                label: 'A -> Z',
                              },
                              {
                                value: 'Events_projectnaam_desc',
                                label: 'Z -> A',
                              },
                              { value: 'Events_status', label: 'Status' },
                              {
                                value: 'Events_capacity_asc',
                                label: 'Capaciteit ↑',
                              },
                              {
                                value: 'Events_capacity_desc',
                                label: 'Capaciteit ↓',
                              },
                            ]}
                          />
                          <RefinementList
                            attribute="status"
                            defaultRefinement={[
                              'Bevestigd',
                              'Optie',
                              'Cancelled',
                            ]}
                          />
                        </div>
                      )}
                    </SelectedHeaderList>
                  </div>
                  {isMobile === false && (
                    <>
                      {this.props.profile.role !== 'gast' && (
                        <div>
                          {/* <Link to={'/create/'} className="event-add">
                            <p>Evenement toevoegen</p>
                            <span>
                              <Plus size={20} />
                            </span>
                          </Link> */}
                          <button
                            className="event-add"
                            onClick={() =>
                              this.setState({
                                addshow: true,
                              })
                            }
                          >
                            <p>Evenement toevoegen</p>
                            <span>
                              <Plus size={20} />
                            </span>
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  <EventAdd
                    show={this.state.addshow}
                    onClose={() =>
                      this.setState({
                        addshow: false,
                      })
                    }
                  />
                </div>
              </div>
              {this.state.calendar === false && (
                <div className="event-list">
                  <table className="striped">
                    <thead>
                      {this.props.profile.dashboardview && (
                        <tr>
                          {isMobile === false && (
                            <th>
                              <SelectAll />
                            </th>
                          )}
                          {isMobile === false && (
                            <>
                              {this.props.profile.dashboardview.datums ===
                                true && <th id="dateC">Datum</th>}
                            </>
                          )}
                          {this.props.profile.dashboardview.projectnaam ===
                            true && <th>Projectnaam</th>}
                          {isMobile === false && (
                            <>
                              {this.props.profile.dashboardview.partner ===
                                true && <th>Contactpersoon</th>}
                              {this.props.profile.dashboardview.venue ===
                                true && <th>Venue</th>}
                              {this.props.profile.dashboardview.adres ===
                                true && <th>Adres</th>}
                              {this.props.profile.dashboardview.capaciteit ===
                                true && <th>Capaciteit</th>}
                              {this.props.profile.dashboardview.contactKVM ===
                                true && <th>Contactpersoon KVM</th>}
                              {this.props.profile.dashboardview
                                .verantwoordelijke === true && (
                                <th>Verantwoordelijke (DJ)</th>
                              )}
                              {this.props.profile.dashboardview.ticketlink ===
                                true && <th>Ticket Link</th>}
                              {this.props.profile.dashboardview.contentlink ===
                                true && <th>Link Gdrive/Dropbox</th>}
                              {this.props.profile.dashboardview.editie ===
                                true && <th>Editie</th>}
                              {this.props.profile.dashboardview.status ===
                                true && <th>Status</th>}
                              {this.props.profile.dashboardview.opmerkingen ===
                                true && <th>Opmerkingen</th>}
                            </>
                          )}
                        </tr>
                      )}
                    </thead>
                    <InfiniteHits
                      showPrevious={true}
                      hitComponent={Hit}
                      cache={sessionStorageCache}
                    />
                  </table>
                </div>
              )}
            </InstantSearch>
            {this.state.calendar === true && <BigCalendar />}
          </div>
        </div>
      </div>
    );
  }
}

function Hit(props) {
  const [events, setEvents] = useContext(selectedEventsContext);
  const [role, setRole] = useState('');
  const [view, setView] = useState('');
  const [copyUser, setCopyUser] = useState('');

  const getUserRole = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((snapshot) => {
        setRole(snapshot.data().role);
      });
  };

  const getDashboardView = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((snapshot) => {
        setView(snapshot.data().dashboardview);
      });
  };

  const eventDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" id="confirmDelete">
            <h1>Weet je het zeker?</h1>
            <p>Dat je dit evenement wilt verwijderen?</p>
            <button className="button" onClick={onClose}>
              Nee
            </button>
            <button
              className="button"
              onClick={deleteEvenement(
                props.hit.objectID,
                props.hit.gastenlijst
              )}
            >
              Verwijderen
            </button>
          </div>
        );
      },
    });
  };

  const getUser = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((doc) => {
        setCopyUser(doc.data());
      });
  };

  if (copyUser === '') {
    getUser();
  }

  const eventCopy = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" id="confirmCopy">
            <h1>Weet je het zeker?</h1>
            <p>Dat je dit evenement wilt kopiëren?</p>
            <button className="button" onClick={onClose}>
              Nee
            </button>
            <button
              className="button"
              onClick={copyEvenement(props.hit, copyUser)}
            >
              kopiëren
            </button>
          </div>
        );
      },
    });
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      // Get full event
      setEvents([...events, props.hit]);
    } else {
      // Remove event if not checked but still in list
      setEvents(events.filter((evnt) => evnt !== props.hit));
    }
  };

  useEffect(() => {
    getUserRole();
    getDashboardView();
  }, []);

  return (
    <>
      {console.log(props.hit)}
      {isMobile === false && (
        <td key={props.hit.objectID}>
          <p>
            <label>
              <input
                type="checkbox"
                id="proje"
                className="filled-in CHCH"
                defaultChecked={false}
                onChange={handleCheckboxChange}
                value={props.hit.objectID}
              />
              <span></span>
            </label>
          </p>
        </td>
      )}
      {isMobile === false && (
        <>
          {view.datums === true && (
            <td id="dateC">
              {props.hit.startdatum && props.hit.einddatum ? (
                <>
                  {props.hit.startdatum === props.hit.einddatum &&
                  !props.hit.begintijd &&
                  !props.hit.eindtijd
                    ? moment(props.hit.startdatum).format('L')
                    : props.hit.startdatum === props.hit.einddatum &&
                      props.hit.begintijd &&
                      props.hit.eindtijd
                    ? moment(props.hit.startdatum).format('L') +
                      `\n` +
                      props.hit.begintijd +
                      ' - ' +
                      props.hit.eindtijd
                    : null}

                  {props.hit.startdatum !== props.hit.einddatum &&
                  props.hit.begintijd
                    ? moment(props.hit.startdatum).format('L') +
                      ' ' +
                      props.hit.begintijd +
                      ' / '
                    : props.hit.startdatum !== props.hit.einddatum &&
                      !props.hit.begintijd
                    ? moment(props.hit.startdatum).format('L') + ' / '
                    : null}
                  <br />
                  {props.hit.startdatum !== props.hit.einddatum &&
                  props.hit.eindtijd
                    ? moment(props.hit.einddatum).format('L') +
                      ' ' +
                      props.hit.eindtijd
                    : props.hit.startdatum !== props.hit.einddatum &&
                      !props.hit.eindtijd
                    ? moment(props.hit.einddatum).format('L')
                    : null}
                </>
              ) : (
                <>
                  {moment(props.hit.startdatum).format('L') +
                    '\n' +
                    props.hit.starttijd +
                    ' - ' +
                    props.hit.eindtijd}
                </>
              )}
            </td>
          )}
        </>
      )}
      {view.projectnaam === true && <td>{props.hit.projectnaam}</td>}
      {isMobile === false && (
        <>
          {view.partner === true && <td>{props.hit.partner}</td>}
          {view.venue === true && <td>{props.hit.venue}</td>}
          {view.adres === true && (
            <td>
              <a
                href={'https://maps.google.com/?q=' + props.hit.adres}
                target="_blank"
                rel="noreferrer"
              >
                {props.hit.adres}
              </a>
            </td>
          )}
          {view.capaciteit === true && <td>{props.hit.capaciteit}</td>}
          {view.contactKVM === true && <td>{props.hit.contactKVM}</td>}
          {view.verantwoordelijke === true && (
            <td>{props.hit.verantwoordelijke}</td>
          )}
          {view.opmerkingen === true && <td>{props.hit.opmerkingen}</td>}
          {view.ticketlink === true && (
            <td>
              {props.hit.ticketlink != null &&
              props.hit.ticketlink != '' &&
              props.hit.ticketlink != '.' ? (
                <a href={props.hit.ticketlink} target="_blank">
                  Klik hier!
                </a>
              ) : null}
            </td>
          )}
          {view.contentlink === true && (
            <td>
              {props.hit.contentlink != null &&
              props.hit.contentlink != '' &&
              props.hit.contentlink != '.' ? (
                <a href={props.hit.contentlink} target="_blank">
                  Klik hier!
                </a>
              ) : null}
            </td>
          )}
          {view.editie === true && <td>{props.hit.editie}</td>}
          {view.status === true && (
            <td>
              {props.hit.status === 'Bevestigd' && (
                <p className="status-block confirmed">{props.hit.status}</p>
              )}
              {props.hit.status === 'Optie' && (
                <p className="status-block concept">{props.hit.status}</p>
              )}
              {props.hit.status === 'Cancelled' && (
                <p className="status-block cancelled">{props.hit.status}</p>
              )}
            </td>
          )}
        </>
      )}
      <td className="i-icons">
        <Link to={'/evenement/' + props.hit.objectID}>
          <Eye />
        </Link>
        {isMobile === false && (
          <>
            {/* eslint-disable-next-line */}
            {role === 'beheerder' && ( // eslint-disable-next-line
              <a>
                <Trash id={props.hit.objectID} onClick={eventDelete} />
              </a>
            )}

            {role !== 'gast' && ( // eslint-disable-next-line
              <a>
                <Copy id={props.hit.objectID} onClick={eventCopy} />
              </a>
            )}
          </>
        )}
      </td>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteEvenement: (id) => {
      dispatch(deleteEvenement(id));
    },
    copyEvenement: (evenement) => {
      dispatch(copyEvenement(evenement));
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Dashboard,
  Hit
);
