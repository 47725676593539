export function getFirstPart(str, divider) {
  return str.split(divider)[0];
}

export function getSecondPart(str, divider) {
  return str.split(divider)[1];
}

export function getThirdPart(str, divider) {
  return str.split(divider)[2];
}
