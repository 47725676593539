import React, { useEffect, useState } from 'react';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useHistory } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import { getFirstPart } from '../../functions/stringSplitting';
import { isMobile } from 'react-device-detect';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import listPlugin from '@fullcalendar/list';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
// const localizer = momentLocalizer(moment);

export default function BigCalendar() {
  const [Cevents, setCevents] = useState([]);
  var snapshot = firebase.firestore().collection('evenementen').get();

  useEffect(() => {
    snapshot.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(
          getFirstPart(moment(doc.data().einddatum.toDate()).format(), 'T') +
            'T' +
            doc.data().eindtijd +
            ':00+02:00'
        );
        setCevents((Cevents) => [
          ...Cevents,
          {
            title: doc.data().editie + ': ' + doc.data().projectnaam,
            start: doc.data().begintijd
              ? getFirstPart(
                  moment(doc.data().startdatum.toDate()).format(),
                  'T'
                ) +
                'T' +
                doc.data().begintijd +
                ':00+02:00'
              : moment(doc.data().startdatum.toDate()).format(),
            end:
              doc.data().einddatum && doc.data().eindtijd
                ? getFirstPart(
                    moment(doc.data().einddatum.toDate()).add(1, 'd').format(),
                    'T'
                  ) +
                  'T' +
                  doc.data().eindtijd +
                  ':00+02:00'
                : // moment(doc.data().einddatum.toDate()).add(1, 'd').format()
                doc.data().einddatum && !doc.data().eindtijd
                ? moment(doc.data().einddatum.toDate()).add(1, 'd').format()
                : moment(doc.data().startdatum.toDate()).format(),
            allDay: doc.data().begintijd || doc.data().eindtijd ? false : true,
            status: doc.data().status,
            id: doc.data().id,
            backgroundColor:
              doc.data().status === 'Bevestigd'
                ? '#09df26'
                : doc.data().status === 'Optie'
                ? '#dad414'
                : '#ff0000',
            borderColor:
              doc.data().status === 'Bevestigd'
                ? '#09df26'
                : doc.data().status === 'Optie'
                ? '#dad414'
                : '#ff0000',
          },
        ]);
      });
    }); // eslint-disable-next-line
  }, []);

  const history = useHistory();

  return (
    <div className="calendar-wrap">
      {/* <Calendar
        popup
        localizer={localizer}
        events={Cevents}
        views={{ month: true }}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: 'blue',
          };

          if (event.status === 'Bevestigd') {
            newStyle.backgroundColor = '#09df26';
          }

          if (event.status === 'Concept') {
            newStyle.backgroundColor = '#dad414';
          }

          if (event.status === 'Cancelled') {
            newStyle.backgroundColor = '#df4609';
          }

          return {
            className: '',
            style: newStyle,
          };
        }}
        onDoubleClickEvent={(event, e) => {
          history.push('/evenement/' + event.id);
        }}
      /> */}
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]}
        initialView={isMobile ? 'listWeek' : 'dayGridMonth'}
        events={Cevents}
        firstDay={1}
        locale="nl"
        weekNumbers={true}
        weekNumberFormat={{ week: 'numeric' }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
        }}
        displayEventEnd={true}
        nextDayThreshold={'03:30:00'}
        eventClick={(event) => {
          history.push('/evenement/' + event.event.id);
        }}
      />
    </div>
  );
}
