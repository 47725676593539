import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editEvenement } from '../../store/actions/evenementActions.js';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import toast, { Toaster } from 'react-hot-toast';
import { firestoreConnect } from 'react-redux-firebase';
import { ArrowLeft } from 'react-feather';
import { Timestamp } from 'firebase/firestore';

import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');

const notify = () =>
  toast.success('Evenement is bijgewerkt, je wordt automatisch doorgestuurd.');

class EditEvenement extends Component {
  state = {
    id: this.props.match.params.id,
    projectnaam: this.props.evenement.projectnaam
      ? this.props.evenement.projectnaam
      : '',
    startdatum: this.props.evenement.startdatum
      ? this.props.evenement.startdatum
      : null,
    einddatum: this.props.evenement.einddatum
      ? this.props.evenement.einddatum
      : null,
    contactKVM: this.props.evenement.contactKVM
      ? this.props.evenement.contactKVM
      : null,
    deal: this.props.evenement.deal ? this.props.evenement.deal : null,
    partner: this.props.evenement.partner
      ? this.props.evenement.partner
      : 'NVT',
    telpartner: this.props.evenement.telpartner
      ? this.props.evenement.telpartner
      : null,
    venue: this.props.evenement.venue ? this.props.evenement.venue : null,
    capaciteit: this.props.evenement.capaciteit
      ? this.props.evenement.capaciteit
      : null,
    adres: this.props.evenement.adres ? this.props.evenement.adres : null,
    status: this.props.evenement.status ? this.props.evenement.status : null,
    editie: this.props.evenement.editie ? this.props.evenement.editie : null,
    opmerkingen: this.props.evenement.opmerkingen
      ? this.props.evenement.opmerkingen
      : null,
    verantwoordelijke: this.props.evenement.verantwoordelijke
      ? this.props.evenement.verantwoordelijke
      : null,
    ticketlink: this.props.evenement.ticketlink
      ? this.props.evenement.ticketlink
      : null,
    contentlink: this.props.evenement.contentlink
      ? this.props.evenement.contentlink
      : null,
    gastenlijst: this.props.evenement.gastenlijst
      ? this.props.evenement.gastenlijst
      : null,
    fotograaf: this.props.evenement.fotograaf
      ? this.props.evenement.fotograaf
      : null,
    telfotograaf: this.props.evenement.telfotograaf
      ? this.props.evenement.telfotograaf
      : null,
    timetable: this.props.evenement.timetable
      ? this.props.evenement.timetable
      : null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleRadioChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  dateChange = (e) => {
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);
    this.setState({
      [e.target.id]: fbTimestamp,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.editEvenement(this.state, this.state.id);
    notify();
    setTimeout(() => {
      this.props.history.push('/evenement/' + this.state.id);
    }, 3000);
  };
  render() {
    const { auth, evenement } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="flex-container">
        <div className="container-wide">
          <button className="btn-icon back-btn" onClick={this.handleBack}>
            <span>
              <ArrowLeft size={20} />
            </span>
            Back
          </button>
          <form onSubmit={this.handleSubmit} className="full-width">
            <div className="form-title">
              <div>
                <h5>BEWERKEN</h5>
                <h6>{evenement.projectnaam}</h6>
              </div>
              <div>
                <button className="button">Evenement Bewerken</button>
                <Toaster />
              </div>
            </div>
            <div className="container-wide-split">
              <div className="split-1">
                <div className="input-field big">
                  <h6>ALGEMEEN</h6>
                </div>
                <div className="input-field big">
                  <label className="active" htmlFor="projectnaam">
                    Projectnaam*
                  </label>
                  <input
                    type="text"
                    id="projectnaam"
                    onChange={this.handleChange}
                    defaultValue={evenement.projectnaam}
                    required
                  ></input>
                </div>
                <div className="input-field small">
                  <select
                    className="browser-default"
                    defaultValue={evenement.editie}
                    onChange={this.handleChange}
                    id="editie"
                  >
                    <option value="" disabled selected>
                      Editie*
                    </option>
                    <option value="Eigen festival">Eigen festival</option>
                    <option value="Clubnacht">Clubnacht</option>
                    <option value="Stagehosting">Stagehosting</option>
                    <option value="Anders">Anders</option>
                  </select>
                </div>
                <div className="input-field small">
                  <select
                    className="browser-default"
                    defaultValue={evenement.status}
                    onChange={this.handleChange}
                    id="status"
                  >
                    <option value="" disabled selected>
                      Status*
                    </option>
                    <option value="Bevestigd">Bevestigd</option>
                    <option value="Optie">Optie</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
                {this.state.editie === 'Eigen festival' ||
                this.state.editie === 'Stagehosting' ||
                this.state.editie === 'Anders' ? (
                  <>
                    {typeof evenement.startdatum !== 'number' && (
                      <div className="input-field small">
                        <label className="active" htmlFor="startdatum">
                          Startdatum*
                        </label>
                        <input
                          type="date"
                          id="startdatum"
                          onChange={this.dateChange}
                          defaultValue={moment(
                            evenement.startdatum.toDate()
                          ).format('yyy-MM-DD')}
                          required
                        ></input>
                      </div>
                    )}
                    {typeof evenement.startdatum === 'number' && (
                      <div className="input-field small">
                        <label className="active" htmlFor="startdatum">
                          Startdatum*
                        </label>
                        <input
                          type="date"
                          id="startdatum"
                          onChange={this.dateChange}
                          defaultValue={moment(evenement.startdatum).format(
                            'yyy-MM-DD'
                          )}
                          required
                        ></input>
                      </div>
                    )}
                    {typeof evenement.einddatum !== 'number' && (
                      <div className="input-field small">
                        <label className="active" htmlFor="einddatum">
                          Einddatum*
                        </label>
                        <input
                          type="date"
                          id="einddatum"
                          onChange={this.dateChange}
                          defaultValue={moment(
                            evenement.einddatum.toDate()
                          ).format('yyy-MM-DD')}
                          required
                        ></input>
                      </div>
                    )}
                    {typeof evenement.einddatum === 'number' && (
                      <div className="input-field small">
                        <label className="active" htmlFor="einddatum">
                          Einddatum*
                        </label>
                        <input
                          type="date"
                          id="einddatum"
                          onChange={this.dateChange}
                          defaultValue={moment(evenement.einddatum).format(
                            'yyy-MM-DD'
                          )}
                          required
                        ></input>
                      </div>
                    )}
                    <div className="input-field small">
                      <label className="active" htmlFor="begintijd">
                        Starttijd*
                      </label>
                      <input
                        type="time"
                        id="begintijd"
                        onChange={this.handleChange}
                        defaultValue={evenement.begintijd}
                        required
                      ></input>
                    </div>
                    <div className="input-field small">
                      <label className="active" htmlFor="eindtijd">
                        Eindtijd*
                      </label>
                      <input
                        type="time"
                        id="eindtijd"
                        onChange={this.handleChange}
                        defaultValue={evenement.eindtijd}
                        required
                      ></input>
                    </div>
                  </>
                ) : this.state.editie === 'Clubnacht' ? (
                  <>
                    {typeof evenement.startdatum !== 'number' && (
                      <div className="input-field small">
                        <label className="active" htmlFor="startdatum">
                          Startdatum*
                        </label>
                        <input
                          type="date"
                          id="startdatum"
                          onChange={this.dateChange}
                          defaultValue={moment(
                            evenement.startdatum.toDate()
                          ).format('yyy-MM-DD')}
                          required
                        ></input>
                      </div>
                    )}
                    {typeof evenement.startdatum === 'number' && (
                      <div className="input-field small">
                        <label className="active" htmlFor="startdatum">
                          Startdatum*
                        </label>
                        <input
                          type="date"
                          id="startdatum"
                          onChange={this.dateChange}
                          defaultValue={moment(evenement.startdatum).format(
                            'yyy-MM-DD'
                          )}
                          required
                        ></input>
                      </div>
                    )}
                    <div className="input-field small"></div>
                    <div className="input-field small">
                      <label className="active" htmlFor="begintijd">
                        Starttijd*
                      </label>
                      <input
                        type="time"
                        id="begintijd"
                        onChange={this.handleChange}
                        defaultValue={evenement.begintijd}
                        required
                      ></input>
                    </div>
                    <div className="input-field small">
                      <label className="active" htmlFor="eindtijd">
                        Eindtijd*
                      </label>
                      <input
                        type="time"
                        id="eindtijd"
                        onChange={this.handleChange}
                        defaultValue={evenement.eindtijd}
                        required
                      ></input>
                    </div>
                  </>
                ) : null}
                <div className="input-field big">
                  <label className="active" htmlFor="adres">
                    Adres
                  </label>
                  <input
                    type="text"
                    id="adres"
                    onChange={this.handleChange}
                    defaultValue={evenement.adres}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="venue">
                    Venue
                  </label>
                  <input
                    type="text"
                    id="venue"
                    onChange={this.handleChange}
                    defaultValue={evenement.venue}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="capaciteit">
                    Capaciteit
                  </label>
                  <input
                    type="text"
                    id="capaciteit"
                    onChange={this.handleChange}
                    defaultValue={evenement.capaciteit}
                  ></input>
                </div>
              </div>
              <div className="split-2">
                <div className="input-field small">
                  <label className="active" htmlFor="partner">
                    Contactpersoon
                  </label>
                  <input
                    type="text"
                    id="partner"
                    onChange={this.handleChange}
                    defaultValue={evenement.partner}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="telpartner">
                    Contactpersoon telefoonnummer
                  </label>
                  <input
                    type="text"
                    id="telpartner"
                    onChange={this.handleChange}
                    defaultValue={evenement.telpartner}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="ticketlink">
                    Ticket link
                  </label>
                  <input
                    type="text"
                    id="ticketlink"
                    onChange={this.handleChange}
                    defaultValue={evenement.ticketlink}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="contentlink">
                    Link naar Gdrive/Dropbox
                  </label>
                  <input
                    type="text"
                    id="contentlink"
                    onChange={this.handleChange}
                    defaultValue={evenement.contentlink}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="contactKVM">
                    Contactpersoon KVM
                  </label>
                  <input
                    type="text"
                    id="contactKVM"
                    onChange={this.handleChange}
                    defaultValue={evenement.contactKVM}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="verantwoordelijke">
                    Verantwoordelijke
                  </label>
                  <input
                    type="text"
                    id="verantwoordelijke"
                    onChange={this.handleChange}
                    defaultValue={evenement.verantwoordelijke}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="fotograaf">
                    Fotograaf
                  </label>
                  <input
                    type="text"
                    id="fotograaf"
                    onChange={this.handleChange}
                    defaultValue={evenement.fotograaf}
                  ></input>
                </div>
                <div className="input-field small">
                  <label className="active" htmlFor="telfotograaf">
                    Fotograaf telefoonnummer
                  </label>
                  <input
                    type="text"
                    id="telfotograaf"
                    onChange={this.handleChange}
                    defaultValue={evenement.telfotograaf}
                  ></input>
                </div>
              </div>
            </div>
            <div className="input-field big">
              <h6>
                OPMERKINGEN
                {this.props.profile.role === 'beheerder' && <span>& DEAL</span>}
              </h6>
            </div>
            {this.props.profile.role === 'beheerder' ? (
              <div className="input-field big-combined">
                <div className="input-field small">
                  <label htmlFor="opmerkingen" className="active">
                    Opmerkingen
                  </label>
                  <textarea
                    id="opmerkingen"
                    className="materialize-textarea"
                    onChange={this.handleChange}
                    defaultValue={evenement.opmerkingen}
                  ></textarea>
                </div>
                <div className="input-field small">
                  <label htmlFor="deal" className="active">
                    Deal
                  </label>
                  <textarea
                    id="deal"
                    className="materialize-textarea"
                    onChange={this.handleChange}
                    defaultValue={evenement.deal}
                  ></textarea>
                </div>
              </div>
            ) : (
              <div className="input-field big">
                <label htmlFor="opmerkingen">Opmerkingen</label>
                <textarea
                  id="opmerkingen"
                  className="materialize-textarea"
                  onChange={this.handleChange}
                  defaultValue={evenement.opmerkingen}
                ></textarea>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const evenementen = state.firestore.data.evenementen;
  const evenement = evenementen ? evenementen[id] : null;
  return {
    evenement: evenement,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    editEvenement: (evenement) =>
      dispatch(
        editEvenement(
          evenement,
          evenement.id
          // evenement.img
        )
      ),
  };
};

export default compose(
  connect(mapStateToProps, mapDistpatchToProps),
  firestoreConnect([
    {
      collection: 'evenementen',
    },
  ])
)(EditEvenement);
