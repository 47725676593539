import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Download } from 'react-feather';

import { selectedEventsContext } from '../../context/SelectedEventList';
import { selectedHeaderContext } from '../../context/SelectedHeaderList';

const EventExport = () => { // eslint-disable-next-line
  const [events, setEvents] = useContext(selectedEventsContext); // eslint-disable-next-line
  const [headers, setHeaders] = useContext(selectedHeaderContext);
  const [xprtEvents, setXprtEvents] = useState([]);

  const fileName = "event_export_" + moment().format('LLL') + '.csv';

  useEffect(() => {
    updateEvents() // eslint-disable-next-line
  }, [])
  

  const updateEvents = () => {
    events.map((evnt) => {

      if (typeof evnt.startdatum === 'object') {
        let showtijdenString = ''
        evnt.einddatum = moment(evnt.einddatum.toDate()).format('L')
        evnt.startdatum = moment(evnt.startdatum.toDate()).format('L')
        if (evnt.showtijden === undefined) {
          evnt.showtijden = ''
        }if (typeof evnt.showtijden !== 'string' && evnt.showtijden !== null) {
          evnt.showtijden.map((shw) => {
            console.log(shw)
            if(typeof shw.datum === 'number') {
              shw = moment(shw.datum).format('L') + ' ' + shw.begintijd + ' - ' + shw.eindtijd
            }else{
              shw = moment(shw.datum.toDate()).format('L') + ' ' + shw.begintijd + ' - ' + shw.eindtijd
            }
            
            if (evnt.showtijden.length > 1){
              showtijdenString = showtijdenString + shw + ' | '
              evnt.showtijden = showtijdenString
            } else {
              evnt.showtijden = shw
            }
            return shw
          })
        }

      } else{
        let showtijdenString = ''
        evnt.einddatum = moment(evnt.einddatum).format('L')
        evnt.startdatum = moment(evnt.startdatum).format('L')
        if (evnt.showtijden === undefined) {
          evnt.showtijden = ''
        }if (typeof evnt.showtijden !== 'string' && evnt.showtijden !== null) {
          evnt.showtijden.map((shw) => {
            shw = moment(shw.datum).format('L') + ' ' + shw.begintijd + ' - ' + shw.eindtijd
            if (evnt.showtijden.length > 1){
              showtijdenString = showtijdenString + shw + ' | '
              evnt.showtijden = showtijdenString
            } else {
              evnt.showtijden = shw
            }
            return shw
          })
        }
      }
      setXprtEvents(xprtEvents => [...xprtEvents, evnt])
      return evnt
    })
  }
  
  const csvReport = {
    data: xprtEvents,
    headers: headers,
    filename: fileName
  };

  return(
      <CSVLink {...csvReport}>
        <button className="btn-icon">
          <span><Download size={20}/>
          </span>Export
        </button>
      </CSVLink>
  )
}



export default EventExport
