import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ChevronRight, ChevronsRight } from 'react-feather';

const NotificationModal = (props) => {
  const { notifications } = props;
  return (
    <div className="modal notificaties hidden" id="notify">
      <div className="notification-container">
        <div className="notification-title">
          <h6>Notificaties</h6>
          <Link to={'/notifylog/'} className="event-add">
            <p>Details</p>
            <span>
              <ChevronRight size={20} />
            </span>
          </Link>
        </div>
        <div className="notificatie-items">
          {notifications &&
            notifications.map((item) => {
              return (
                <div key={item.id} className="notificatie-item">
                  <p>{item.eventname + ' ' + item.task}</p>
                  {item.type === 'create' && (
                    <>
                      {item.eventcreator && (
                        <p className="greyed-out">{item.eventcreator}</p>
                      )}
                    </>
                  )}
                  {item.type === 'update' && (
                    <>
                      {item.eventupdater && (
                        <p className="greyed-out">{item.eventupdater}</p>
                      )}
                    </>
                  )}
                  <p className="greyed-out">
                    {moment(item.time.toDate()).fromNow()}
                  </p>
                  {item.eventid && (
                    <Link
                      to={`/evenement/${item.eventid}`}
                      className="event-add"
                    >
                      <p>Ga naar evenement</p>
                      <span>
                        <ChevronsRight size={20} />
                      </span>
                    </Link>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
