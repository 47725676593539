import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import firebase from 'firebase/compat';
import { firestoreConnect } from 'react-redux-firebase';
// import { Redirect, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ArrowLeft, Edit2, Trash } from 'react-feather';

import { Timestamp } from 'firebase/firestore';

import {
  getFirstPart,
  getSecondPart,
} from '../../../functions/stringSplitting';

import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');

const initialStage = {
  stagename: '',
  date: '',
  artists: [],
};

const initialArtist = {
  name: '',
  setStart: '',
  setEnd: '',
  setStartH: null,
  setStartM: null,
  setEndH: null,
  setEndM: null,
  bookingStatus: '',
  totalFee: 0,
  remarks: '',
  artistContent: '',
};

const notifyStageDelete = () => {
  toast.success('De stage is succesvol verwijderd');
};

const notifyStageAdded = () => {
  toast.success('De stage is succesvol toegevoegd');
};

const Timetable = (props) => {
  const { evenement } = props;
  const [stageModal, setStageModal] = useState(false);
  const [artistStageModal, setAtistStageModal] = useState(false);

  const handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  const handleStageModal = (e) => {
    e.preventDefault();
    setStageModal(!stageModal);
  };

  const handleArtistStageModal = (e) => {
    e.preventDefault();
    setAtistStageModal(!artistStageModal);
  };

  const CountFee = ({ timetable }) => {
    let totalFee = 0;
    timetable.artists.forEach((artist) => {
      totalFee += artist.totalFee;
    });
    return (
      <>
        <td className="floatr">Totaalbedrag:</td>
        <td>€{totalFee}</td>
      </>
    );
  };

  if (evenement !== null) {
    return (
      <div className="dashboard container-wide">
        <Toaster />
        <button className="btn-icon back-btn" onClick={handleBack}>
          <span>
            <ArrowLeft size={20} />
          </span>
          Back
        </button>

        <div className="row">
          <div className="col s12">
            <StagePopupModal
              open={stageModal}
              stages={evenement.timetable}
              eventId={props.match.params.id}
              onClose={handleStageModal}
            />
            <ArtistStagePopupModal
              open={artistStageModal}
              stages={evenement.timetable}
              eventId={props.match.params.id}
              onClose={handleArtistStageModal}
            />
            <div className="title">
              <h1>Timetable - {evenement.projectnaam}</h1>
            </div>
            {evenement.timetable && (
              <div className="action-buttons">
                <button
                  className="button-icon-right"
                  onClick={handleStageModal}
                >
                  <p>Podia's bewerken</p>
                  <span>
                    <Edit2 size={20} />
                  </span>
                </button>
                <button
                  className="button-icon-right"
                  onClick={handleArtistStageModal}
                >
                  <p>Artiesten bewerken</p>
                  <span>
                    <Edit2 size={20} />
                  </span>
                </button>
              </div>
            )}
            <>
              {evenement.timetable.map((timetable, Tindex) => (
                <div className="timetable-container">
                  <h2>
                    {timetable.stagename} -{' '}
                    {moment(timetable.date.toDate()).format('DD-MM-YYYY')}
                  </h2>
                  <table
                    className="striped wide-table"
                    id={
                      'table_' +
                      Tindex +
                      '_' +
                      moment(timetable.date.toDate()).format('DD-MM-YYYY')
                    }
                  >
                    <thead>
                      <tr>
                        <th>Artiest</th>
                        <th>Settijden</th>
                        <th>Status booking</th>
                        <th className="with-label">
                          Total fee<span>(incl. BF, Excl. BTW)</span>
                        </th>
                        <th>Opmerkingen</th>
                        <th>Presskit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...timetable.artists]
                        //TODO: sort correctly by time
                        .sort((a, b) =>
                          a.setStartH === 23 && b.setStartH === 0 ? -1 : 1
                        )
                        .map((artist, Aindex) => (
                          <tr key={Aindex}>
                            <td>{artist.name}</td>
                            <td>
                              {artist.setStartH < 10
                                ? '0' + artist.setStartH
                                : artist.setStartH}
                              :
                              {artist.setStartM < 10
                                ? '0' + artist.setStartM
                                : artist.setStartM}{' '}
                              -{' '}
                              {artist.setEndH < 10
                                ? '0' + artist.setEndH
                                : artist.setEndH}
                              :
                              {artist.setEndM < 10
                                ? '0' + artist.setEndM
                                : artist.setEndM}
                            </td>
                            <td>{artist.bookingStatus}</td>
                            <td>€{artist.totalFee}</td>
                            <td>{artist.remarks}</td>
                            <td>
                              {artist.artistContent !== '' && (
                                <a href={artist.artistContent}>Klik hier</a>
                              )}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <CountFee timetable={timetable} />
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </>
          </div>
        </div>
      </div>
    );
  }
};

const StagePopupModal = (props) => {
  const { open, stages, eventId, onClose } = props;
  const [newStage, setNewStage] = useState(initialStage);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'date') {
      let date = new Date(value + 'T00:00:00');
      let fbTimestamp = Timestamp.fromDate(date);
      setNewStage({ ...newStage, [name]: fbTimestamp });
    } else {
      setNewStage({ ...newStage, [name]: value });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm('Weet je zeker dat je deze stage wilt verwijderen?')) {
      const arr = [...stages];

      const fromIndex = e.target.attributes.index.nodeValue;

      arr.splice(fromIndex, 1);

      firebase.firestore().collection('evenementen').doc(eventId).update({
        timetable: arr,
      });
      notifyStageDelete();
    } else {
    }
  };

  const indexChange = (e) => {
    const arr = [...stages];

    const fromIndex = e.target.attributes.index.nodeValue;
    const toIndex = e.target.value;

    const element = arr.splice(fromIndex, 1)[0];

    arr.splice(toIndex, 0, element);

    firebase.firestore().collection('evenementen').doc(eventId).update({
      timetable: arr,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .firestore()
      .collection('evenementen')
      .doc(eventId)
      .update({
        timetable: firebase.firestore.FieldValue.arrayUnion(newStage),
      });

    notifyStageAdded();
  };

  if (open) {
    return (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <button className="btn-icon back-btn" onClick={onClose}>
              <span>
                <ArrowLeft size={20} />
              </span>
              Back
            </button>
            <h2 className="modal-title">Stage toevoegen</h2>
          </div>
          <div className="modal-body">
            <div className="half-container">
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <div className="input-field small">
                  <label htmlFor="stagename">Stage naam*</label>
                  <input
                    type="text"
                    id="stagename"
                    name="stagename"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field small">
                  <label htmlFor="date" className="active">
                    Datum*
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="button-side">
                  <button className="button">Stage toevoegen</button>
                </div>
              </form>
            </div>
            {stages && (
              <div className="half-container">
                {stages.map((stage, index) => (
                  <div key={index} className="stagetitle">
                    <select
                      className="browser-default select-number"
                      onChange={indexChange}
                      name="indexing"
                      id="indexing"
                      index={index}
                      defaultValue={index}
                    >
                      {stages.map((stage, index) => (
                        <>
                          <option value={index}>{index + 1}</option>
                        </>
                      ))}
                    </select>
                    <h4>
                      {stage.stagename +
                        ' - ' +
                        moment(stage.date.toDate()).format('DD-MM-YYYY')}
                    </h4>
                    <button index={index} onClick={handleDelete}>
                      X
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const ArtistStagePopupModal = (props) => {
  // eslint-disable-next-line
  const { open, stages, eventId, onClose } = props;
  const [newArtistStage, setNewArtistStage] = useState(null);
  const [newArtist, setNewArtist] = useState(initialArtist);
  const [newStageID, setNewStageID] = useState(0);

  const dupStage = [...stages];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'stagename') {
      setNewStageID(parseInt(value));
    }

    if (name === 'totalFee') {
      setNewArtist({ ...newArtist, [name]: parseInt(value) });
    } else {
      setNewArtist({ ...newArtist, [name]: value });
    }

    if (name === 'setStart') {
      setNewArtist({
        ...newArtist,
        [name]: value,
        setStartH: parseInt(getFirstPart(value, ':')),
        setStartM: parseInt(getSecondPart(value, ':')),
      });
    }

    if (name === 'setEnd') {
      console.log('pleh');
      setNewArtist({
        ...newArtist,
        [name]: value,
        setEndH: parseInt(getFirstPart(value, ':')),
        setEndM: parseInt(getSecondPart(value, ':')),
      });
    }
  };

  useEffect(() => {
    // if (newArtist.setStart !== '') {
    //   setNewArtist({
    //     ...newArtist,
    //     setStartH: parseInt(getFirstPart(newArtist.setStart, ':')),
    //     setStartM: parseInt(getSecondPart(newArtist.setStart, ':')),
    //   });
    // }

    // if (newArtist.setEndH === null && newArtist.setEnd !== '') {
    //   setNewArtist({
    //     ...newArtist,
    //     setEndH: parseInt(getFirstPart(newArtist.setEnd, ':')),
    //     setEndM: parseInt(getSecondPart(newArtist.setEnd, ':')),
    //   });
    // }
    if (newArtistStage !== null) {
      dupStage.splice(newStageID, 1, newArtistStage);
      console.log(dupStage);

      firebase.firestore().collection('evenementen').doc(eventId).update({
        timetable: dupStage,
      });
    } // eslint-disable-next-line
  }, [newArtistStage]);

  // const handleEdit = (e) => {
  //   e.preventDefault();
  //   firebase
  //     .firestore()
  //     .collection('evenementen')
  //     .doc(eventId)
  //     .get()
  //     .then((doc) => {
  //       const data = doc.data();
  //       console.log(data.timetable[0].artists[0]);
  //     });
  // };

  const handleDelete = (e) => {
    e.preventDefault();
    let updatedStage = {};
    var oldStages = [...stages];
    var oldArtists = [...stages[getFirstPart(e.target.id, ':')].artists];
    for (var i = 0; i < oldArtists.length; i++) {
      if (oldArtists[i].name === getSecondPart(e.target.id, ':')) {
        oldArtists.splice(i, 1);
        updatedStage = {
          ...oldStages[getFirstPart(e.target.id, ':')],
          artists: oldArtists,
        };
        oldStages.splice(getFirstPart(e.target.id, ':'), 1, updatedStage);
        firebase.firestore().collection('evenementen').doc(eventId).update({
          timetable: oldStages,
        });
      }
    }

    // console.log(oldStages);
  };

  const dataToState = (date, stagename, artists) => {
    setNewArtistStage({
      date: date,
      stagename: stagename,
      artists: artists,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newArtists = [...stages[newStageID].artists];

    newArtists.push(newArtist);

    dataToState(
      stages[newStageID].date,
      stages[newStageID].stagename,
      newArtists
    );
  };

  if (open) {
    return (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <button className="btn-icon back-btn" onClick={onClose}>
              <span>
                <ArrowLeft size={20} />
              </span>
              Back
            </button>
            <h2 className="modal-title">Artiesten bewerken</h2>
          </div>
          <div className="modal-body">
            <div className="half-container">
              <form
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
                onSubmit={handleSubmit}
              >
                <div
                  className="form-columns"
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                  }}
                >
                  <div className="columns-1">
                    <div className="input-field">
                      <select
                        className="browser-default"
                        onChange={handleChange}
                        name="stagename"
                        id="stagename"
                      >
                        <option value="" disabled selected>
                          Selecteer een stage
                        </option>
                        {stages.map((stage, index) => (
                          <>
                            <option value={index}>{stage.stagename}</option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="input-field">
                      <label htmlFor="name">Naam*</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-field">
                      <label htmlFor="setStart" className="active">
                        Start tijd*
                      </label>
                      <input
                        type="time"
                        id="setStart"
                        name="setStart"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-field">
                      <label htmlFor="setEnd" className="active">
                        Eind tijd*
                      </label>
                      <input
                        type="time"
                        id="setEnd"
                        name="setEnd"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="columns-2">
                    <div className="input-field">
                      <select
                        className="browser-default"
                        onChange={handleChange}
                        name="bookingStatus"
                        id="bookingStatus"
                      >
                        <option value="" disabled selected>
                          Selecteer de status van deze booking*
                        </option>
                        <option value="optie">Optie</option>
                        <option value="bevestigd">Bevestigd</option>
                        <option value="geannuleerd">Geannuleerd</option>
                        <option value="nog uit te vragen">
                          Nog uit te vragen
                        </option>
                      </select>
                    </div>
                    <div className="input-field">
                      <label htmlFor="totalFee">
                        Total Fee (incl. BF, excl. BTW)*
                      </label>
                      <input
                        type="number"
                        id="totalFee"
                        name="totalFee"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-field">
                      <label htmlFor="remarks">Opmerkingen</label>
                      <textarea
                        style={{ resize: 'vertical' }}
                        id="remarks"
                        name="remarks"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-field">
                      <label htmlFor="artistContent">Presskit</label>
                      <input
                        type="text"
                        id="artistContent"
                        name="artistContent"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="button">
                  Artiest toevoegen
                </button>
              </form>
            </div>
            {stages && (
              <div className="half-container altcontainer">
                {stages.map((stage, index) => (
                  <>
                    <div key={index} className="stagetitle">
                      <h4>
                        {stage.stagename +
                          ' - ' +
                          moment(stage.date.toDate()).format('DD-MM-YYYY')}
                      </h4>
                    </div>
                    <div style={{ width: '100%' }}>
                      <table
                        className="striped wide-table"
                        id={
                          'table_' +
                          index +
                          '_' +
                          moment(stage.date.toDate()).format('DD-MM-YYYY')
                        }
                      >
                        <thead>
                          <tr>
                            <th>Artiest</th>
                            <th>Settijden</th>
                            <th>Status booking</th>
                            <th className="with-label">
                              Total fee <br />
                              <span>(incl. BF, Excl. BTW)</span>
                            </th>
                            <th>Opmerkingen</th>
                            <th>Presskit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[...stage.artists]
                            .sort((a, b) =>
                              a.setStartH === 23 && b.setStartH === 0 ? -1 : 1
                            )
                            .map((artist, Aindex) => (
                              <tr key={Aindex}>
                                <td>{artist.name}</td>
                                <td>
                                  {artist.setStartH < 10
                                    ? '0' + artist.setStartH
                                    : artist.setStartH}
                                  :
                                  {artist.setStartM < 10
                                    ? '0' + artist.setStartM
                                    : artist.setStartM}{' '}
                                  -{' '}
                                  {artist.setEndH < 10
                                    ? '0' + artist.setEndH
                                    : artist.setEndH}
                                  :
                                  {artist.setEndM < 10
                                    ? '0' + artist.setEndM
                                    : artist.setEndM}
                                </td>
                                <td>{artist.bookingStatus}</td>
                                <td>€{artist.totalFee}</td>
                                <td>{artist.remarks}</td>
                                <td>
                                  {artist.artistContent !== '' && (
                                    <a href={artist.artistContent}>Klik hier</a>
                                  )}
                                </td>
                                <td>
                                  {/* <Edit2 onClick={handleEdit} /> */}
                                  <Trash
                                    id={index + ':' + artist.name}
                                    onClick={handleDelete}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const evenementen = state.firestore.data.evenementen;
  const evenement = evenementen ? evenementen[id] : null;
  return {
    evenement: evenement,
    profile: state.firebase.profile,
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect([
    {
      collection: 'evenementen',
    },
  ])
)(Timetable);
