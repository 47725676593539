import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

export default function ForgotPwd() {
  var errorMessage = '';
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      url: 'https://eventplanner.thisislifegroup.nl',
      handleCodeInApp: true,
    };

    firebase
      .auth()
      .sendPasswordResetEmail(email, config)
      .then(() => {
        const notify = () =>
          toast.success(
            'Er is een mail verzonden naar ' +
              email +
              '. Check je inbox en SPAM box!'
          );
        notify();
      })
      .catch((error) => {
        const notify = () => toast.success(error.message);
        notify();
      });
  };

  return (
    <div className="flex-container-height">
      <Toaster />
      <div className="image-round"></div>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <div className="form-title">
            <div>
              <h5>EVENTPLANNING</h5>
              <h6>THIS IS LIFE GROUP</h6>
            </div>
          </div>
          <div className="input-field">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <div className="submit-bar">
            <Link to={'/'} className="fgPwd">
              Ik weet hem weer!
            </Link>
            <div>
              <button className="button" disabled={!email}>
                Sent password reset
              </button>
              <div className="red-text center">
                {errorMessage ? <p>{errorMessage}</p> : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
