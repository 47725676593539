import React from 'react';
import { Link } from 'react-router-dom';

const EventAdd = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div className="modal" id="add-event">
      <div className="modal-content">
        <div className="modal-body">
          <div className="quickAdd-container">
            <h4>
              Snel <br /> toevoegen
            </h4>
            <p>
              Op deze manier kan je snel een evenement toevoegen aan de
              evenementen planner!
            </p>
            <Link to={'/quick-create/'} className="button">
              Snel aan de slag!
            </Link>
          </div>
          <div className="quickAdd-container">
            <h4>
              Gedetailleerd <br /> toevoegen
            </h4>
            <p>
              Hierin kan je je evenement aanvullen met alle informatie die je
              maar wilt!
            </p>
            <Link to={'/create/'} className="button-alt">
              Slow and steady!
            </Link>
          </div>
        </div>
        <div className="modal-footer">
          <button className="button-red" onClick={props.onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventAdd;
