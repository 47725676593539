import firebase from 'firebase/compat/app';

export const signIn = (credentials) => {
  return (dispatch, getState) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCES' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCES' });
      });
  };
};

export const signUp = (newUser) => {
  return (dispatch, getState) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firebase
          .firestore()
          .collection('users')
          .doc(resp.user.uid)
          .set({
            firstname: newUser.firstName,
            lastname: newUser.lastName,
            initials: newUser.firstName[0] + newUser.lastName[0],
            role: newUser.role,
            dashboardview: {
              programmeur: false,
              capaciteit: false,
              datums: true,
              barleverancier: false,
              adres: true,
              productiemanager: false,
              projecteigenaar: false,
              plaats: true,
              projectmanager: false,
              projectnaam: true,
              status: true,
              horecamanagershow: false,
              brouwerij: false,
              beveiliging: false,
              provincie: false,
              venue: true,
              organisator: true,
              partner: true,
              opmerkingen: false,
              restleverancier: false,
              horecamanager: false,
              financemanager: false,
              marketingmanager: false,
              administratie: false,
            },
          });
      })
      .then(() => {
        dispatch({
          type: 'SIGNUP_SUCCES',
        });
      })
      .catch((err) => {
        dispatch({
          type: 'SIGNUP_ERROR',
          err,
        });
      });
  };
};

export const updateDashboardView = (dashboardview) => {
  return (dispatch, getState) => {
    var user = firebase.auth().currentUser;
    firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .update({
        dashboardview: dashboardview,
      })
      .then(() => {
        dispatch({ type: 'UPDATE_USER_VIEW', dashboardview });
      })
      .catch((err) => {
        dispatch({ type: 'UPDATE_USER_VIEW_ERROR', err });
      });
  };
};

const reauthenticate = (currentPWD) => {
  var user = firebase.auth().currentUser;
  var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPWD);
  return user.reauthenticateWithCredential(cred);
};

export const updateEmail = (currentPWD, email) => {
  return (dispatch, getState) => {
    reauthenticate(currentPWD).then(() => {
      var user = firebase.auth().currentUser;
      user
        .updateEmail(email)
        .then(() => {
          dispatch({ type: 'UPDATE_EMAIL_SUCCES' });
        })
        .catch((err) => {
          dispatch({ type: 'UPDATE_EMAIL_ERROR', err });
        });
    });
  };
};

export const updatePwd = (currentPWD, newPWD) => {
  return (dispatch, getState) => {
    reauthenticate(currentPWD).then(() => {
      var user = firebase.auth().currentUser;
      user
        .updatePassword(newPWD)
        .then(() => {
          dispatch({ type: 'UPDATE_PASSWORD_SUCCES' });
        })
        .catch((err) => {
          dispatch({ type: 'UPDATE_PASSWORD_ERROR', err });
        });
    });
  };
};
