import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');

export function getDaysBetweenDays(startDate, endDate) {
  var days = [];

  if (startDate !== endDate) {
    //eslint-disable-next-line
    var startDate = new Date(startDate); //eslint-disable-next-line
    var endDate = new Date(endDate);
    var currentDate = startDate;
    while (currentDate <= endDate) {
      days.push(moment(new Date(currentDate)).format('DD/MM'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return days;
}
