import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { signUp } from '../../store/actions/authActions'
import { ArrowLeft } from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Gebruiker aangemaakt, je bent nu ingelogd als de aangemaakte gebruiker');
class SignUp extends Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    role: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleBack =(e) => {
    e.preventDefault()
    window.history.back()
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signUp(this.state)
    notify()
    setTimeout(() => {this.props.history.push('/')}, 4000)
    
  }
  render() {
    const { authError, auth } = this.props
    if (!auth.uid) return <Redirect to='/signin' />

    return (
      <div className="flex-container-center">
        <div className="signup-container">
          <button className="btn-icon back-btn" onClick={this.handleBack}> <span><ArrowLeft size={20}/>
          </span>Back</button>
          <form onSubmit={this.handleSubmit}>
            <div className="form-title">
              <div>
                <h5>TOEVOEGEN</h5>
                <h6>NIEUWE GEBRUIKER</h6>
              </div>
            </div>
            <div className="input-field">
              <label htmlFor="firstName">Voornaam*</label>
              <input type="text" id="firstName" onChange={this.handleChange} required></input>
            </div>
            <div className="input-field">
              <label htmlFor="lastName">Achternaam*</label>
              <input type="text" id="lastName" onChange={this.handleChange} required></input>
            </div>
            <div className="input-field">
              <label htmlFor="email">Email*</label>
              <input type="email" id="email" onChange={this.handleChange} required></input>
            </div>
            <div className="input-field">
              <label htmlFor="password">Wachtwoord*</label>
              <input type="password" id="password" onChange={this.handleChange} required></input>
              <span className="helper-text" data-error="wrong" data-success="right">Wachtwoord moet 6 tekens bevatten!</span>
            </div>
            <div className="input-field">
                  <select className="browser-default" onChange={this.handleChange} id="role" required>
                    <option value="" disabled selected>Rol*</option>
                    <option value='beheerder'>Beheerder</option>
                    <option value='bewerker'>Bewerker</option>
                    <option value='gast'>Gast</option>
                  </select>
                </div>
            <div className="input-field">
              <button className="button">Gebruiker aanmaken</button>
              <div className="red-text center"> { authError ? <p>{authError}</p> : null}</div>
            </div>
          </form>
          <Toaster />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))
