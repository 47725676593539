import { uploadPhoto } from './imgActions';
import firebase from 'firebase/compat/app';

export const createEvenement = (evenement, img) => {
  return (dispatch, getState) => {
    const creatorId = getState().firebase.auth.uid;
    const creatorProfile = getState().firebase.profile;
    // const guestRef = null;
    var eventRef = null;

    let imgRef = { fullPath: 'images/default.jpg' };
    if (typeof img !== 'undefined') {
      imgRef = uploadPhoto(img, img.name);
      console.log(img);
    }

    // make Async call to database to make event!
    firebase
      .firestore()
      .collection('evenementen')
      .add({
        ...evenement,
        img: imgRef.fullPath,
        einddatum: evenement.einddatum
          ? evenement.einddatum
          : evenement.startdatum,
        createdBy: creatorProfile.firstname + ' ' + creatorProfile.lastname,
        creatorUID: creatorId,
      })
      .then((docRef) => {
        dispatch({ type: 'CREATE_EVENEMENT', evenement });
        //make Async call to database to make guestlist.
        eventRef = docRef.id;
        firebase
          .firestore()
          .collection('guestlists')
          .add({
            eventID: docRef.id,
            guests: null,
          })
          .then((docRef) => {
            firebase
              .firestore()
              .collection('evenementen')
              .doc(eventRef)
              .update({
                gastenlijst: docRef.id,
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CREATE_EVENEMENT_ERROR', err });
      });
  };
};

export const copyEvenement = (evenement, copyUser) => {
  return (dispatch) => {
    var eventRef = null;
    // make Async call to database
    firebase
      .firestore()
      .collection('evenementen')
      .add({
        administratie: evenement.administratie ? evenement.administratie : null,
        adres: evenement.adres ? evenement.adres : null,
        begintijd: evenement.begintijd ? evenement.begintijd : null,
        capaciteit: evenement.capaciteit ? evenement.capaciteit : null,
        contentlink: evenement.contentlink ? evenement.contentlink : null,
        contactKVM: evenement.contactKVM ? evenement.contactKVM : null,
        deal: null,
        verantwoordelijke: evenement.verantwoordelijke
          ? evenement.verantwoordelijke
          : null,
        editie: evenement.editie ? evenement.editie : null,
        einddatum: null,
        eindtijd: evenement.eindtijd ? evenement.eindtijd : null,
        img: evenement.img ? evenement.img : null,
        opmerkingen: evenement.opmerkingen ? evenement.opmerkingen : null,
        partner: evenement.partner ? evenement.partner : null,
        projectnaam: evenement.projectnaam + '(copy)',
        startdatum: evenement.startdatum ? evenement.startdatum : null,
        status: evenement.status ? evenement.status : null,
        ticketlink: evenement.ticketlink ? evenement.ticketlink : null,
        venue: evenement.venue ? evenement.venue : null,
        updatedBy: null,
        updaterUID: null,
        createdBy: copyUser.firstname + ' ' + copyUser.lastname,
        creatorUID: null,
        gastenlijst: evenement.gastenlijst ? evenement.gastenlijst : null,
      })
      .then(function (docRef) {
        console.log('Copied_event');

        //make Async call to database to make guestlist.
        eventRef = docRef.id;
        firebase
          .firestore()
          .collection('guestlists')
          .add({
            eventID: docRef.id,
            guests: null,
          })
          .then((docRef) => {
            firebase
              .firestore()
              .collection('evenementen')
              .doc(eventRef)
              .update({
                gastenlijst: docRef.id,
              });
          });

        setTimeout(() => {
          window.location.replace('/evenement/' + docRef.id);
        }, 3000);
      })
      // .then(() => {
      //   // dispatch({type: 'COPIED_EVENEMENT', evenement})
      //   console.log('Copied_event');
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 3000);
      // })
      .catch((err) =>
        // dispatch({type: 'COPIED_EVENEMENT_ERROR', err})
        console.log(err)
      );
  };
};

export const editEvenement = (evenement, id) => {
  return (dispatch, getState) => {
    const updaterId = getState().firebase.auth.uid;
    const updaterProfile = getState().firebase.profile;

    // make Async call to database
    firebase
      .firestore()
      .collection('evenementen')
      .doc(id)
      .update({
        ...evenement,
        einddatum: evenement.einddatum
          ? evenement.einddatum
          : evenement.startdatum,
        updatedBy: updaterProfile.firstname + ' ' + updaterProfile.lastname,
        updaterUID: updaterId,
      })
      .then(() => {
        dispatch({ type: 'UPDATE_EVENEMENT', evenement });
      })
      .catch((err) => {
        dispatch({ type: 'UPDATE_EVENEMENT_ERROR', err });
      });
  };
};

export const editEvenementImage = (evenement, id, img) => {
  return (dispatch, getState) => {
    let imgRef = { fullPath: 'images/default.jpg' };
    if (typeof img !== 'undefined') {
      imgRef = uploadPhoto(img.img, img.img.name);
    }

    // make Async call to database
    firebase
      .firestore()
      .collection('evenementen')
      .doc(id)
      .update({
        ...evenement,
        img: imgRef.fullPath,
      })
      .then(() => {
        dispatch({ type: 'UPDATE_EVENEMENT_IMG', evenement });
      })
      .catch((err) => {
        dispatch({ type: 'UPDATE_EVENEMENT_IMG_ERROR', err });
      });
  };
};

export const deleteEvenement = (id, guestlistID) => {
  return (dispatch, getState) => {
    // make Async call to database
    firebase
      .firestore()
      .collection('evenementen')
      .doc(id)
      .delete()
      .then(() => {
        firebase.firestore().collection('guestlists').doc(guestlistID).delete();
      });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
};
