const initState = {
  evenementen: [
    {
      id: '1', 
      projectnaam: 'Qmusic',
      datum: '05 / 03 / 2021',
      organisator: 'OOTG',
      partner: 'NVT',
      plaats: 'Reusel',
      venue: 'El Sombrero',
      adres: 'Wilhelminalaan 71, 5541 CT Reusel',
      status: 'Bevestigd',
    },
    {
      id: '2', 
      projectnaam: 'WiSH Outdoor',
      datum: '02 / 07 / 2021 - 04 / 07 / 2021',
      organisator: 'EWH / OOTG',
      partner: 'Bromios',
      plaats: 'Beek en Donk',
      venue: 'Outdoor terrein',
      adres: 'Lekerstraat, 5741 SM Beek en Donk',
      status: 'Concept',
    },
    {
      id: '3', 
      projectnaam: 'Paaspop',
      datum: '03 / 09 / 2021 - 05 / 09 / 2021',
      organisator: 'EWH',
      partner: 'Stichting Paaspop',
      plaats: 'Schijndel',
      venue: 'Molenheide',
      adres: 'Vijverweg 22, 5482 ZN Schijndel',
      status: 'Cancelled',
    },
  ]
}

const eventReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_EVENEMENT':
      console.log('Created Evenement', action.evenement);
      return state;
    case 'CREATE_EVENEMENT_ERROR':
      console.log('Create evenement error', action.err);
      return state;
      case 'COPIED_EVENEMENT':
      console.log('Copied Evenement');
      return state;
    case 'COPIED_EVENEMENT_ERROR':
      console.log('Copied evenement error', action.err);
      return state;
    case 'UPDATE_EVENEMENT':
      console.log('Updated evenement' , action.evenement);
      return state;
    case 'UPDATE_EVENEMENT_ERROR':
        console.log('Update evenement error', action.err);
        return state;
    case 'UPDATE_EVENEMENT_IMG':
      console.log('Updated evenement image' , action.evenement);
      return state;
    case 'UPDATE_EVENEMENT_IMG_ERROR':
        console.log('Update evenement image error', action.err);
        return state;
    default:
      return state;
  }
}

export default eventReducer
