import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createEvenement } from '../../store/actions/evenementActions.js';
import { Redirect } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ArrowLeft } from 'react-feather';
import { Timestamp } from 'firebase/firestore';

const notify = () =>
  toast.success('Evenement is aangemaakt, je wordt automatisch doorgestuurd.');

class CreateEvenement extends Component {
  state = {
    projectnaam: '',
    startdatum: null,
    begintijd: null,
    contactKVM: null,
    deal: null,
    einddatum: null,
    eindtijd: null,
    partner: 'NVT',
    telpartner: null,
    venue: null,
    capaciteit: null,
    adres: null,
    status: '',
    editie: '',
    opmerkingen: null,
    img: 'images/default.jpg',
    verantwoordelijke: null,
    ticketlink: null,
    contentlink: null,
    gastenlijst: null,
    fotograaf: null,
    telfotograaf: null,
    timetable: [],
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleRadioChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  dateChange = (e) => {
    let date = new Date(e.target.value + 'T00:00:00');
    let fbTimestamp = Timestamp.fromDate(date);
    this.setState({
      [e.target.id]: fbTimestamp,
    });
  };

  handleImageUpload = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] !== 'undefined') {
      this.setState({
        [e.target.id]: e.target.files[0],
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createEvenement(this.state, this.state.img);
    notify();
    setTimeout(() => {
      this.props.history.push('/');
    }, 3000);
  };
  render() {
    const { auth } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className="flex-container">
        <div className="container-wide">
          <button className="btn-icon back-btn" onClick={this.handleBack}>
            <span>
              <ArrowLeft size={20} />
            </span>
            Back
          </button>
          <form onSubmit={this.handleSubmit} className="full-width">
            <div className="form-title">
              <div>
                <h5>TOEVOEGEN</h5>
                <h6>NIEUW EVENEMENT</h6>
              </div>
              <div>
                <button className="button" type="submit">
                  Evenement toevoegen
                </button>
                <Toaster />
              </div>
            </div>
            <div className="container-wide-split">
              <div className="split-1">
                <div className="input-field big">
                  <h6>ALGEMEEN</h6>
                </div>
                <div className="input-field big">
                  <label htmlFor="projectnaam">Projectnaam*</label>
                  <input
                    type="text"
                    id="projectnaam"
                    onChange={this.handleChange}
                    required
                  ></input>
                </div>
                <div className="input-field small">
                  <select
                    className="browser-default"
                    onChange={this.handleChange}
                    id="editie"
                    required
                  >
                    <option value="" disabled selected>
                      Editie*
                    </option>
                    <option value="Eigen festival">Eigen festival</option>
                    <option value="Clubnacht">Clubnacht</option>
                    <option value="Stagehosting">Stagehosting</option>
                    <option value="Anders">Anders</option>
                  </select>
                </div>
                <div className="input-field small">
                  <select
                    className="browser-default"
                    onChange={this.handleChange}
                    id="status"
                    required
                  >
                    <option value="" disabled selected>
                      Status*
                    </option>
                    <option value="Optie">Optie</option>
                    <option value="Bevestigd">Bevestigd</option>
                    <option value="Geannuleerd">Geannuleerd</option>
                  </select>
                </div>
                {this.state.editie === 'Eigen festival' ||
                this.state.editie === 'Stagehosting' ||
                this.state.editie === 'Anders' ? (
                  <>
                    <div className="input-field small">
                      <label className="active" htmlFor="startdatum">
                        Startdatum*
                      </label>
                      <input
                        type="date"
                        id="startdatum"
                        onChange={this.dateChange}
                        required
                      ></input>
                    </div>
                    <div className="input-field small">
                      <label className="active" htmlFor="einddatum">
                        Einddatum*
                      </label>
                      <input
                        type="date"
                        id="einddatum"
                        onChange={this.dateChange}
                        required
                      ></input>
                    </div>
                    <div className="input-field small">
                      <label className="active" htmlFor="begintijd">
                        Starttijd*
                      </label>
                      <input
                        type="time"
                        id="begintijd"
                        onChange={this.handleChange}
                        required
                      ></input>
                    </div>
                    <div className="input-field small">
                      <label className="active" htmlFor="eindtijd">
                        Eindtijd*
                      </label>
                      <input
                        type="time"
                        id="eindtijd"
                        onChange={this.handleChange}
                        required
                      ></input>
                    </div>
                  </>
                ) : this.state.editie === 'Clubnacht' ? (
                  <>
                    <div className="input-field small">
                      <label className="active" htmlFor="startdatum">
                        Datum*
                      </label>
                      <input
                        type="date"
                        id="startdatum"
                        onChange={this.dateChange}
                        required
                      ></input>
                    </div>
                    <div className="input-field small"></div>
                    <div className="input-field small">
                      <label className="active" htmlFor="begintijd">
                        Starttijd*
                      </label>
                      <input
                        type="time"
                        id="begintijd"
                        onChange={this.handleChange}
                        required
                      ></input>
                    </div>
                    <div className="input-field small">
                      <label className="active" htmlFor="eindtijd">
                        Eindtijd*
                      </label>
                      <input
                        type="time"
                        id="eindtijd"
                        onChange={this.handleChange}
                        required
                      ></input>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="input-field small">
                      <input
                        className="disabled"
                        type="date"
                        id="startdatum"
                      ></input>
                    </div>
                    <div className="input-field small">
                      <input
                        className="disabled"
                        type="date"
                        id="einddatum"
                      ></input>
                    </div>
                    <div className="input-field small">
                      <input
                        className="disabled"
                        type="time"
                        id="begintijd"
                      ></input>
                    </div>
                    <div className="input-field small">
                      <input
                        className="disabled"
                        type="time"
                        id="eindtijd"
                      ></input>
                    </div>
                  </>
                )}
                <div className="input-field big">
                  <label htmlFor="adres">Adres*</label>
                  <input
                    type="text"
                    id="adres"
                    onChange={this.handleChange}
                    required
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="venue">Venue</label>
                  <input
                    type="text"
                    id="venue"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="capaciteit">Capaciteit</label>
                  <input
                    type="text"
                    id="capaciteit"
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <div className="split-2">
                <div className="input-field small">
                  <label htmlFor="partner">Contactpersoon</label>
                  <input
                    type="text"
                    id="partner"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="telpartner">
                    Contactpersoon telefoonnummer
                  </label>
                  <input
                    type="text"
                    id="telpartner"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="opmerkingen">Ticket link</label>
                  <textarea
                    id="ticketlink"
                    className="materialize-textarea"
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="input-field small">
                  <label htmlFor="contentlink">Link naar Gdrive/Dropbox</label>
                  <input
                    type="text"
                    id="contentlink"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="contactKVM">Contactpersoon KVM</label>
                  <input
                    type="text"
                    id="contactKVM"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="verantwoordelijke">Verantwoordelijke</label>
                  <input
                    type="text"
                    id="verantwoordelijke"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="fotograaf">Fotograaf</label>
                  <input
                    type="text"
                    id="fotograaf"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field small">
                  <label htmlFor="telfotograaf">Fotograaf telefoonnummer</label>
                  <input
                    type="text"
                    id="telfotograaf"
                    onChange={this.handleChange}
                  ></input>
                </div>
                <div className="input-field upload big">
                  <label htmlFor="image">Afbeelding uploaden:</label>
                  <input
                    type="file"
                    name="image"
                    id="img"
                    onChange={this.handleImageUpload}
                  />
                </div>
              </div>
            </div>
            <div className="input-field big"></div>
            <div className="input-field big">
              <h6>
                OPMERKINGEN{' '}
                {this.props.profile.role === 'beheerder' && <span>& DEAL</span>}
              </h6>
            </div>
            {this.props.profile.role === 'beheerder' ? (
              <div className="input-field big-combined">
                <div className="input-field small">
                  <label htmlFor="opmerkingen">Opmerkingen</label>
                  <textarea
                    id="opmerkingen"
                    className="materialize-textarea"
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="input-field small">
                  <label htmlFor="deal">Deal</label>
                  <textarea
                    id="deal"
                    className="materialize-textarea"
                    onChange={this.handleChange}
                  ></textarea>
                </div>
              </div>
            ) : (
              <div className="input-field big">
                <label htmlFor="opmerkingen">Opmerkingen</label>
                <textarea
                  id="opmerkingen"
                  className="materialize-textarea"
                  onChange={this.handleChange}
                ></textarea>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    createEvenement: (evenement, img) =>
      dispatch(createEvenement(evenement, img)),
  };
};

export default connect(mapStateToProps, mapDistpatchToProps)(CreateEvenement);
