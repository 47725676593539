import { createContext, useState } from 'react';

export const selectedHeaderContext = createContext();

const SelectedHeaderList = (props) => {
  const [headers, setHeaders] = useState([])

  return (
    <selectedHeaderContext.Provider value={[headers, setHeaders]}>
      {props.children}
    </selectedHeaderContext.Provider>
  );
};

export default SelectedHeaderList;
