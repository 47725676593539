import { createContext, useState } from 'react';

export const selectedEventsContext = createContext();

const SelectedEventList = (props) => {
  const [events, setEvents] = useState([]);

  return (
    <selectedEventsContext.Provider value={[events, setEvents]}>
      {props.children}
    </selectedEventsContext.Provider>
  );
};

export default SelectedEventList;
