import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Edit2, ArrowLeft, Eye } from 'react-feather';
import { GetPhoto } from '../../store/actions/imgActions';
import { isMobile } from 'react-device-detect';

import moment from 'moment';
import 'moment/locale/nl';
import EditImageModal from '../models/EditImageModal';

moment.locale('nl');

const EventDetails = (props) => {
  const { evenement } = props;

  const [img, setImg] = useState(
    'https://firebasestorage.googleapis.com/v0/b/eventplanner-kvm.appspot.com/o/images%2Fdefault.jpg?alt=media&token=9f1b5e80-31a6-4a81-a6a3-c61b66b3ab51'
  );

  const handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  useEffect(() => {
    if (evenement) {
      if (evenement.img !== 'images/undefined') {
        GetPhoto(evenement.img)?.then((res) => {
          setImg(res);
        });
      } else {
        return null;
      }
    }
    // eslint-disable-next-line
  });

  if (evenement !== null) {
    return (
      <div className="container section project-details">
        <button className="btn-icon back-btn" onClick={handleBack}>
          <span>
            <ArrowLeft size={20} />
          </span>
          Back
        </button>
        <div className="header">
          <img className="header-img" src={img} alt="event"></img>
          {isMobile === false && (
            <>
              {props.profile.role !== 'gast' && (
                <button
                  className="editImg"
                  onClick={() => {
                    const modal = document.getElementById('imageEditModal');
                    modal.style.display = 'block';
                  }}
                >
                  <Edit2 size={20} />
                </button>
              )}
            </>
          )}
        </div>
        <EditImageModal event={evenement} />
        <div className="head-content">
          <div>
            <h1>{evenement.projectnaam}</h1>
            <h5>{evenement.venue}</h5>
          </div>
          <div>
            {evenement.status === 'Bevestigd' && (
              <p className="status-block confirmed">{evenement.status}</p>
            )}
            {evenement.status === 'Optie' && (
              <p className="status-block concept">{evenement.status}</p>
            )}
            {evenement.status === 'Cancelled' && (
              <p className="status-block cancelled">{evenement.status}</p>
            )}
          </div>
        </div>
        <div className="head-subcontent">
          {evenement.gastenlijst && (
            <Link
              to={'/guestlist/' + evenement.gastenlijst}
              className="button-icon-right"
            >
              <p>Gastenlijst bewerken</p>
              <span>
                <Edit2 size={20} />
              </span>
            </Link>
          )}
          {evenement.timetable && (
            <Link
              to={'/timetable/' + props.match.params.id}
              className="button-icon-right"
            >
              <p>Timetable bekijken</p>
              <span>
                <Eye size={20} />
              </span>
            </Link>
          )}
          {isMobile === false && (
            <>
              {props.profile.role !== 'gast' && (
                <Link
                  to={'/update/' + props.match.params.id}
                  className="button-icon-right"
                >
                  <p>Evenement bewerken</p>
                  <span>
                    <Edit2 size={20} />
                  </span>
                </Link>
              )}
            </>
          )}
        </div>
        <div className="main-content">
          <div className="info-container">
            {evenement.partner ||
            evenement.startdatum ||
            evenement.einddatum ||
            evenement.adres ||
            evenement.capaciteit ||
            evenement.ticketlink ||
            evenement.contactKVM ||
            evenement.verantwoordelijke ||
            evenement.contentlink ||
            evenement.fotograaf ? (
              <>
                <h6 className="info-title">ALGEMEEN</h6>
                <div className="info-section">
                  {evenement.partner && (
                    <div className="info-item">
                      <label>Contactpersoon</label>
                      {evenement.partner && evenement.telpartner ? (
                        <p>
                          {evenement.partner}{' '}
                          <a href={'tel:' + evenement.telpartner}>
                            {evenement.telpartner}
                          </a>
                        </p>
                      ) : (
                        <p>{evenement.partner}</p>
                      )}
                    </div>
                  )}
                  {evenement.editie === 'Clubnacht' ? (
                    <>
                      {evenement.startdatum &&
                      evenement.begintijd &&
                      evenement.eindtijd ? (
                        <>
                          {typeof evenement.startdatum !== 'number' && (
                            <div className="info-item">
                              <label>Datum & tijd</label>
                              <p>
                                {moment(evenement.startdatum.toDate()).format(
                                  'DD-MM-yyy'
                                ) +
                                  ' ' +
                                  evenement.begintijd +
                                  ' - ' +
                                  evenement.eindtijd}
                              </p>
                            </div>
                          )}
                          {typeof evenement.startdatum === 'number' && (
                            <div className="info-item">
                              <label>Datum & tijd</label>
                              <p>
                                {moment(evenement.startdatum).format(
                                  'DD-MM-yyy'
                                ) +
                                  ' ' +
                                  evenement.begintijd +
                                  ' - ' +
                                  evenement.eindtijd}
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {typeof evenement.startdatum !== 'number' && (
                            <div className="info-item">
                              <label>Startdatum</label>
                              <p>
                                {moment(evenement.startdatum.toDate()).format(
                                  'DD-MM-yyy'
                                )}
                              </p>
                            </div>
                          )}
                          {typeof evenement.startdatum === 'number' && (
                            <div className="info-item">
                              <label>Startdatum</label>
                              <p>
                                {moment(evenement.startdatum).format(
                                  'DD-MM-yyy'
                                )}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : evenement.editie !== 'Clubnacht' ? (
                    <>
                      {evenement.startdatum && evenement.begintijd ? (
                        <>
                          {typeof evenement.startdatum !== 'number' && (
                            <div className="info-item">
                              <label>Startdatum & tijd</label>
                              <p>
                                {moment(evenement.startdatum.toDate()).format(
                                  'DD-MM-yyy'
                                ) +
                                  ' ' +
                                  evenement.begintijd}
                              </p>
                            </div>
                          )}
                          {typeof evenement.startdatum === 'number' && (
                            <div className="info-item">
                              <label>Startdatum & tijd</label>
                              <p>
                                {moment(evenement.startdatum).format(
                                  'DD-MM-yyy'
                                ) +
                                  ' ' +
                                  evenement.begintijd}
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {typeof evenement.startdatum !== 'number' && (
                            <div className="info-item">
                              <label>Startdatum</label>
                              <p>
                                {moment(evenement.startdatum.toDate()).format(
                                  'DD-MM-yyy'
                                )}
                              </p>
                            </div>
                          )}
                          {typeof evenement.startdatum === 'number' && (
                            <div className="info-item">
                              <label>Startdatum</label>
                              <p>
                                {moment(evenement.startdatum).format(
                                  'DD-MM-yyy'
                                )}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                  {evenement.editie !== 'Clubnacht' ? (
                    <>
                      {evenement.einddatum && evenement.eindtijd ? (
                        <>
                          {typeof evenement.einddatum !== 'number' && (
                            <div className="info-item">
                              <label>Einddatum & tijd</label>
                              <p>
                                {moment(evenement.einddatum.toDate()).format(
                                  'DD-MM-yyy'
                                ) +
                                  ' ' +
                                  evenement.eindtijd}
                              </p>
                            </div>
                          )}
                          {typeof evenement.einddatum === 'number' && (
                            <div className="info-item">
                              <label>Einddatum & tijd</label>
                              <p>
                                {moment(evenement.einddatum).format(
                                  'DD-MM-yyy'
                                ) +
                                  ' ' +
                                  evenement.eindtijd}
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {typeof evenement.einddatum !== 'number' && (
                            <div className="info-item">
                              <label>Einddatum</label>
                              <p>
                                {moment(evenement.einddatum.toDate()).format(
                                  'DD-MM-yyy'
                                )}
                              </p>
                            </div>
                          )}
                          {typeof evenement.einddatum === 'number' && (
                            <div className="info-item">
                              <label>Einddatum</label>
                              <p>
                                {moment(evenement.einddatum).format(
                                  'DD-MM-yyy'
                                )}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                  {evenement.adres && (
                    <div className="info-item">
                      <label>Adres</label>
                      <a
                        href={'https://maps.google.com/?q=' + evenement.adres}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>{evenement.adres}</p>
                      </a>
                    </div>
                  )}
                  {evenement.capaciteit && (
                    <div className="info-item">
                      <label>Capaciteit</label>
                      <p>{evenement.capaciteit}</p>
                    </div>
                  )}
                  {evenement.ticketlink === true ||
                  evenement.ticketlink !== null ||
                  evenement.ticketlink !== '' ||
                  evenement.ticketlink !== '.' ? (
                    <div className="info-item">
                      <label>Ticket link</label>
                      <a
                        href={evenement.ticketlink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>Klik hier voor de ticketshop</p>
                      </a>
                    </div>
                  ) : null}
                  {evenement.contactKVM && (
                    <div className="info-item">
                      <label>Contactpersoon KVM</label>
                      <p>{evenement.contactKVM}</p>
                    </div>
                  )}
                  {evenement.verantwoordelijke && (
                    <div className="info-item">
                      <label>Verantwoordelijke</label>
                      <p>{evenement.verantwoordelijke}</p>
                    </div>
                  )}
                  {evenement.contentlink === true ||
                  evenement.contentlink !== null ||
                  evenement.contentlink !== '' ||
                  evenement.contentlink !== '.' ? (
                    <div className="info-item">
                      <label>Link voor Gdrive/Dropbox</label>
                      <a
                        href={evenement.contentlink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>Klik hier voor de content</p>
                      </a>
                    </div>
                  ) : null}
                  {evenement.fotograaf && (
                    <div className="info-item">
                      <label>Fotograaf</label>
                      {evenement.fotograaf && evenement.telfotograaf ? (
                        <p>
                          {evenement.fotograaf}{' '}
                          <a href={'tel:' + evenement.telfotograaf}>
                            {evenement.telfotograaf}
                          </a>
                        </p>
                      ) : (
                        <p>{evenement.fotograaf}</p>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : null}
            {evenement.opmerkingen || evenement.deal ? (
              <>
                <h6 className="info-title">
                  Opmerkingen{' '}
                  {props.profile.role === 'beheerder' && evenement.deal ? (
                    <span>& Deal</span>
                  ) : null}
                </h6>
                {evenement.opmerkingen && (
                  <div className="info-item">
                    <label>opmerkingen</label>
                    <p>{evenement.opmerkingen}</p>
                  </div>
                )}
                {props.profile.role === 'beheerder' && evenement.deal ? (
                  <div className="info-item">
                    <label>Deal</label>
                    <p>{evenement.deal}</p>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container center">
        <p>Evenement laden....</p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const evenementen = state.firestore.data.evenementen;
  const facturen = state.firestore.data.facturen;
  const evenement = evenementen ? evenementen[id] : null;
  return {
    evenement: evenement,
    profile: state.firebase.profile,
    facturen: facturen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetPhoto: (path) => dispatch(GetPhoto(path)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: 'evenementen',
    },
    {
      collection: 'facturen',
    },
  ])
)(EventDetails);
