import React, { useRef, useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import toast, { Toaster } from 'react-hot-toast';
import { HotTable } from '@handsontable/react';
import { registerAllModules, registerAllPlugins } from 'handsontable/registry';
import { getDaysBetweenDays } from '../../functions/dateFunctions';

import moment from 'moment';
import 'moment/locale/nl';

import 'handsontable/dist/handsontable.full.css';

import { ArrowLeft } from 'react-feather';

moment.locale('nl');

// register Handsontable's modules
registerAllModules();
registerAllPlugins();

const notify = () => toast.success('Gastenlijst opgeslagen!');

const Guestlist = (props) => {
  const { guestlist } = props;

  const [evenement, setEvenement] = useState([]);
  const [eventDays, setEventDays] = useState([]);

  const setEventData = (organ) => {
    var eventRef = firebase
      .firestore()
      .collection('evenementen')
      .doc(guestlist.eventID);

    if (evenement.length === 0) {
      console.log('evenement is empty');
      eventRef.get().then((doc) => {
        if (doc.exists) {
          const eventData = doc.data();
          setEvenement(eventData);
          console.log('evenement filled');
        } else {
          console.log('evenement does not exist');
        }
      });
    }
  };

  const initialData = [
    { firstname: '', lastname: '', email: '', phone: '', remarks: '' },
  ];

  const updateGuestlistData = (updatedlist) => {
    var guestlistRef = firebase
      .firestore()
      .collection('guestlists')
      .doc(evenement.gastenlijst);

    guestlistRef.update({
      guests: updatedlist,
    });

    guestlistRef.update({
      guests: updatedlist,
    });
  };

  useEffect(() => {
    if (guestlist) {
      setEventData(guestlist.eventID);
    }

    getEventDays();
    // eslint-disable-next-line
  }, [evenement, guestlist]);

  const handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  const getEventDays = () => {
    if (evenement !== [] && evenement.startdatum !== undefined) {
      console.log('ellende');
      console.log(moment(evenement.startdatum.toDate()).format('DD/MM'));
      console.log(evenement.einddatum);
      // setEventDays(moment(evenement.startdatum.toDate()).format('DD/MM'));

      if (
        moment(evenement.startdatum.toDate()).format('DD/MM') ===
        moment(evenement.einddatum).format('DD/MM')
      ) {
        // if event is on one day, return 1 day
        setEventDays([moment(evenement.startdatum.toDate()).format('DD/MM')]);
      } else {
        //if event is on multiple days, return all days in between start and end in array to display
        setEventDays(
          // Get the days between the start and end date if event is mulitple days
          getDaysBetweenDays(
            moment(evenement.startdatum.toDate()),
            moment(evenement.einddatum.toDate())
          )
        );
      }
    }
  };

  const hotTableComponent = useRef(null);

  const emailValidator = /.+@.+/;

  var dynamicHeader = [{ label: 'Dagen', colspan: 5 }];
  for (var i = 0; i < eventDays.length; i++) {
    dynamicHeader.push({ label: eventDays[i], colspan: 1 });
  }

  var dynamicColHeader = []; // eslint-disable-next-line
  for (var i = 0; i < eventDays.length; i++) {
    dynamicColHeader.push(eventDays[i]);
  }

  var dynamicDayColumns = [
    {
      data: 'firstname',
      label: 'Voornaam',
    },
    {
      data: 'lastname',
      label: 'Achternaam',
    },
    {
      data: 'email',
      label: 'email',
      validator: emailValidator,
      allowInvalid: true,
    },
    {
      data: 'phone',
      label: 'Telefoonnummer',
    },
    {
      data: 'remarks',
      label: 'Opmerkingen',
    },
  ]; // eslint-disable-next-line
  for (var i = 0; i < eventDays.length; i++) {
    dynamicDayColumns.push({
      data: eventDays[i],
      label: eventDays[i],
      type: 'checkbox',
    });
    if (i === eventDays.length - 1) {
      dynamicDayColumns.push({
        readOnly: true,
      });
    }
  }

  const hotTableSettings = {
    startCols: 8,
    startRows: 50,
    fixedRowsTop: [0, 2],
    rowHeaders: true,
    stretchH: 'last',
    className: 'htLeft htMiddle',
    wordWrap: false,
    colWidths: [120, 150, 250, 150, 5, 5],
    rowHeights: 32,
    columnHeaderHeight: 32,
    rowHeaderWidth: 50,
    exportFile: true,
    minSpareRows: 50,
  };

  const saveData = () => {
    const data = hotTableComponent.current.hotInstance.getSourceData();
    updateGuestlistData(data);
    notify();
  };

  const exportFile = () => {
    const exportPlugin = hotTableComponent.current.hotInstance.getPlugin(
      'exportFile'
    );

    exportPlugin.downloadFile('csv', {
      bom: false,
      columnDelimiter: ',',
      columnHeaders: true,
      fileExtension: 'csv',
      filename: 'Handsontable-CSV-file_[YYYY]-[MM]-[DD]',
      mimeType: 'text/csv',
      rowDelimiter: '\r\n',
      rowHeaders: true,
    });
  };

  if (guestlist !== null) {
    return (
      <div className="dashboard container-wide">
        <Toaster />
        <button className="btn-icon back-btn" onClick={handleBack}>
          <span>
            <ArrowLeft size={20} />
          </span>
          Back
        </button>

        <div className="row">
          <div className="col s12">
            <div className="title">
              <h1>Gastenlijst - {evenement.projectnaam}</h1>
              <div>
                <button className="button" onClick={exportFile}>
                  Download as CSV
                </button>
                <button className="button" onClick={saveData}>
                  Save Changes
                </button>
              </div>
            </div>
            <div id="hot-app">
              <HotTable
                data={
                  guestlist.guests !== null
                    ? JSON.parse(JSON.stringify(guestlist.guests))
                    : initialData
                }
                id={'guestlist-' + props.match.params.id}
                ref={hotTableComponent}
                settings={hotTableSettings}
                nestedHeaders={[
                  dynamicHeader,
                  [
                    'Voornaam',
                    'Achternaam',
                    'Email',
                    'Telefoonnummer',
                    'Opmerkingen',
                  ],
                ]}
                colHeaders={[
                  'Voornaam',
                  'Achternaam',
                  'Email',
                  'Telefoonnummer',
                  'Opmerkingen',
                  dynamicColHeader,
                ]}
                columns={dynamicDayColumns}
                licenseKey="non-commercial-and-evaluation"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container center">
        <p>Gastenlijst laden....</p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const guestlists = state.firestore.data.guestlists;
  const guestlist = guestlists ? guestlists[id] : null;
  return {
    profile: state.firebase.profile,
    guestlist: guestlist,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'evenementen',
    },
    {
      collection: 'guestlists',
    },
  ])
)(Guestlist);
